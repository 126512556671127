/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // scroll to target

        if ("ontouchstart" in window || navigator.msMaxTouchPoints) {
          var isTouch = true;
        } else {
          var isTouch = false;
        }

        function scrolltotarget(target, offset) {
          $("body, html").animate({
            scrollTop: target - offset
          }, 1000);
        }

        // hash check

        $(document).ready(function() {
          if (window.location.hash) {
            var hash = window.location.hash;
            var targetheight = $(hash).offset().top;
            var headerheight = 100;

            scrolltotarget(targetheight, headerheight);
          }
        });

        // fade in

        $(window).scroll(function() {
          $('.fade-in').each(function() {
              var top_of_element = $(this).offset().top;
              var bottom_of_element = $(this).offset().top + $(this).outerHeight();
              var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
              var top_of_screen = $(window).scrollTop();

              if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) && !$(this).hasClass('is-visible')) {
                  $(this).addClass('is-visible');
              }
          });
        });

        // sticky header

        var new_scroll_position = 0;
        var last_scroll_position;
        var header = document.querySelector('.main-header');

        window.addEventListener('scroll', function() {
          last_scroll_position = window.scrollY;

          if(last_scroll_position > 1) {
            header.classList.add('scrolled');
          } else {
            header.classList.remove('scrolled');
          }

          new_scroll_position = last_scroll_position;
        });

        function closePopup(target) {
          $('body').removeClass('popup-visible');
          var video = target.find('video');
          video.trigger('pause');
          target.removeClass('active');
          $('.popup-wrapper').removeClass('active');
        }

        $('.mobile-nav-trigger').bind('click touchstart', function(event) {
          event.preventDefault();
          $('body').toggleClass('nav-popup-visible');
          $(this).toggleClass('active');

          if($('.nav-primary-mobile .mobile-parent').hasClass('active')) {
            $('.nav-primary-mobile .mobile-parent').removeClass('active');
            $('.nav-primary-mobile .mobile-parent').find('.sub-menu').removeClass('active').slideUp();
          }

          var popuptarget = $('.popup-video-wrapper.active');

          if(popuptarget){
            closePopup(popuptarget);
          }
        });

        /* mobile nav sub menus */

        var canclickmenuitem = true;
        $('.sub-menu-trigger').bind('click touchstart', function (event) {
          event.preventDefault();
          if (canclickmenuitem) {
            canclickmenuitem = false;
            $(this).parent().toggleClass('active').find('.sub-menu-wrapper').toggleClass('active').slideToggle(300, function() {
              // Animation complete.
              canclickmenuitem = true;
            });
          }
        });

        function closeMobileNav() {
          $('body').removeClass('nav-popup-visible');
          $('.mobile-nav-trigger').removeClass('active');
          $('.nav-primary-mobile .mobile-parent').removeClass('active');
          $('.nav-primary-mobile .mobile-parent').find('.sub-menu').removeClass('active').slideUp();
          canclickmenuitem = true;
        }

        $('.mobile-nav-trigger-close').bind('click touchstart', function(event) {
          event.preventDefault();
          closeMobileNav();
        });

        $('.trigger-play').bind('click touchstart', function(event) {
          event.preventDefault();
          $(this).toggleClass('active');
          var othertarget = $('.popup-video-wrapper.active');
          if(othertarget.length > 0){
            closePopup(othertarget);
          }
          var target = $(this).next('.popup-video-wrapper');
          var video = target.find('video');

          $('body').toggleClass('popup-visible');

          target.addClass('active');

          video.trigger('play');
        });

        $('.popup-overlay').bind('click touchstart', function(event) {
          event.preventDefault();
          var target = $('.popup-video-wrapper.active');
          var video = target.find('video');
          closePopup(target);
        });

        $('.popup-close').bind('click touchstart', function(event) {
          event.preventDefault();
          var target = $(this).parent('.popup-video-wrapper');
          var video = target.find('video');
          closePopup(target);
        });

        if(window.innerWidth > 1024) {
          closeMobileNav();
        }

        $(window).resize(function(){
          if(window.innerWidth > 1024) {
            setTimeout(function() {
              closeMobileNav();
            }, 100);
          }
        });

        $('.icon-item').click(function() {
          if(isTouch) {
            $(this).toggleClass('active');
          }
        });

        $('.product-rows .product-trigger').click(function() {
          $('.product-trigger').removeClass('active');
          $('.product-item').removeClass('active');
          $(this).addClass('active').closest('.product-item').addClass('active');
        });

        $('.product-image-trigger').click(function() {
          $(this).toggleClass('active');
          $(this).prev('.overlay-image').toggleClass('active');
        });

        $('.scroll-up').click(function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        $('.list-link').click(function(e) {
          e.preventDefault;
          var targetitem = $(this).attr('href');
          var targetheight =  $(targetitem).offset().top;
          var headerheight = 100;

          scrolltotarget(targetheight, headerheight);
        });


        $('.main-section-trigger').click(function() {
          var targetheight = $(this).closest('.standard-page-banner, .alternative-page-banner, .home-banner').height();
          var headerheight = 100;

          if($('.alternative-page-banner .bottom-row').length) {
            targetheight = targetheight - ($('.alternative-page-banner .bottom-row').height());
          }

          scrolltotarget(targetheight, headerheight);
        });

        $('.collage-carousel').each(function() {
          $(this).slick({
            arrows: false,
            dots: true,
            autoplay: false,
            autoplaySpeed: 5000,
            speed: 1000,
            appendDots: $(this).next('.dots'),
          });

          if($(this).hasClass('autoplay')) {
            $(this).addClass('mukodik');
            $(this).slick('slickSetOption', 'autoplay', true, true);
          }
        });

        $('.alternative-page-banner-carousel').slick({
          arrows: false,
          dots: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 1000,
          appendDots: '.page-banner-dots',
        });

        $('.nav-primary a').bind('click touchstart', function (event) {
          url = $(this).attr('href');
          hash = url.split('#')[1];

          if (hash) {
            closeMobileNav();
            var targetheight = $('#' + hash).offset().top;
            var headerheight = 100;
            scrolltotarget(targetheight, headerheight);
          } else {
          }
        });
      }
    },
    'vertical_tabs_section' : {
      init: function() {

      },
      finalize: function() {
        function verticaltabclick(origin, inittarget) {
          if(history.pushState) {
            history.pushState(null, null, origin);
          } else {
            location.hash = origin;
          }

          var target;

          if(window.innerWidth < 768) {
            target = 'mobile-' + inittarget;
          } else {
            target = inittarget;
          }

          if($(origin).hasClass('active')) {
            if(window.innerWidth < 768) {
              $(origin).removeClass('active');
              $('#' + target).removeClass('active');
            }
          } else {
            $(origin).parent().children('.tab-item').removeClass('active');
            $(origin).parent().next('.tab-content-wrapper').children('.tab-content').removeClass('active');
            $(origin).addClass('active');
            $('#' + target).addClass('active');
          }
        }

        if(window.innerWidth < 768) {
          $('.vertical-tabs .tab-item').removeClass('active');
        }

        var urltarget = window.location.hash;
        window.initpageload = true;

        if($(urltarget).length){
          var origin = urltarget;
          var inittarget = $(origin).attr('data-target');

          var target;

          if(window.innerWidth < 768) {
            target = 'mobile-' + inittarget;
          } else {
            target = inittarget;
          }

          verticaltabclick(origin, target);
        }

        $('.vertical-tabs .tab-item .trigger').click(function() {
          var origin = '#' + $(this).parent().attr('id');
          var inittarget = $(this).parent().attr('data-target');
          verticaltabclick(origin, inittarget);
          //if(history.pushState) {
            //history.pushState(null, null, origin);
          //} else {
            //location.hash = origin;
          //}
        });
      }
    },
    'horizontal_tabs_section' : {
      init: function() {
      },
      finalize: function() {
        // scroll to target

        function scrolltotarget(target, offset) {
          $("html, body").animate({
            scrollTop: target - offset
          }, 1000);
        }

        if(window.innerWidth < 1440) {
          $('.horizontal-tab-rows .tab-item').each(function() {
            if($(this).hasClass('active')) {
              $(this).find('.mobile-tab-content-wrapper').addClass('active');
            }
          });
        }

        function tabclick(target, mark, origin) {
          if(window.innerWidth < 1440) {
            if(history.pushState) {
              history.pushState(null, null, origin);
            } else {
              location.hash = origin;
            }

            if($('.horizontal-tabs .tab-item[data-target="' + target + '"]').hasClass('active')) {
              $('.horizontal-tabs .tab-item[data-target="' + target + '"]').removeClass('active').find('.mobile-tab-content-wrapper').removeClass('active');
            } else {
              $('.horizontal-tabs .tab-item').removeClass('active').find('.mobile-tab-content-wrapper').removeClass('active');
              $('.horizontal-tabs .tab-item[data-target="' + target + '"]').addClass('active').find('.mobile-tab-content-wrapper').addClass('active');
            }

          } else {
            if(history.pushState) {
              history.pushState(null, null, origin);
            } else {
              location.hash = origin;
            }

            $('.horizontal-tabs .tab-item').removeClass('active');
            $('.horizontal-tabs .tab-item[data-mark="' + mark + '"]').addClass('active');

            $('.horizontal-tabs-content .tab-content').removeClass('active');
            $(target).addClass('active');

            var targetheight = $(origin).offset().top;
            var offset = 100;

            scrolltotarget(targetheight, offset);

          }
        }

        $('.horizontal-tabs .tab-item .trigger').click(function() {
          var target = $(this).parent().attr('data-target');
          var mark = $(this).parent().attr('data-mark');
          var origin;

          if($(this).parent().hasClass('bottom-tab-item')) {
            origin = '#' + $('.horizontal-tabs .top-tab-item[data-mark="' + mark + '"]').attr('id');
          } else {
            origin = '#' + $(this).parent().attr('id');
          }

          tabclick(target, mark, origin);
        });


        var urltarget = window.location.hash;
      //  window.initpageload = true;

        if($(urltarget).length){
          target = $(urltarget).attr('data-target');
          mark = $(urltarget).attr('data-mark');
          origin = urltarget;

          tabclick(target, mark, origin);
        }
      }
    },
    'blog' : {
      init: function() {

      },
      finalize: function() {
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
